<template>
    <div>
        <portal to="layout-default-header">
            <div>
                <!-- Back Button -->
                <btn-icon
                    variant="primary"
                    icon="arrow-left"
                    class="mb-4"
                    @click="$router.back()"
                />

                <b-row align-v="end">
                    <b-col class="mb-3 mb-sm-0">
                        <h1 class="mb-0">
                            {{ $t('leagues.teams.edit.title') }}
                        </h1>
                    </b-col>

                    <b-col class="text-right">
                        <btn-resource
                            class="btn-primary btn-min-w"
                            :resource="league"
                            @click="save()"
                        >
                            {{ $t('save') }}
                        </btn-resource>
                    </b-col>
                </b-row>
            </div>
        </portal>

        <!-- Hide tabs if there is only 1 group. -->
        <b-tabs
            :nav-wrapper-class="[
                'text-center mb-4',
                {'d-none': league.league_groups.length === 1},
            ]"
            nav-class="nav-items-group d-inline-flex"
            pills
            @input="currentGroup = league.league_groups.models[$event]"
        >
            <b-tab
                v-for="(group, index) in league.league_groups.models"
                :key="index"
                :title="group.name"
                title-link-class="text-uppercase"
            >
                <div class="text-right mb-3">
                    <strong>
                        {{ $t('leagues.model.league_group_teams.label') }}

                        <span class="text-primary">
                            {{ group.league_group_teams.length }}/{{ league.max_teams }}
                        </span>
                    </strong>
                </div>

                <teams-table
                    :rows="group.league_group_teams.models.slice().reverse()"
                    @removeClicked="removeGroupTeamFromGroup($event, group)"
                >
                    <!-- Insert Team autocomplete component in the first row. -->
                    <template #firstRow="{columns}">
                        <tr class="bg-light">
                            <td :colspan="columns.length">
                                <!-- Disable the autocomplete if the group has max amount of teams. -->
                                <auto-complete
                                    class="bg-white"
                                    input-class="border-0"
                                    :disabled="group.league_group_teams.length == league.max_teams"
                                    reset-query
                                    :placeholder="$t('teams.autocomplete.placeholder')"
                                    :url="Team.endpoint"
                                    @onClickOption="addTeamToCurrentGroup($event.original)"
                                >
                                    <template #default="{option}">
                                        <!-- Only show the team if the group doesn't have it. -->
                                        <teams-dd-item
                                            v-if="!group.league_group_teams.has({team: option.original})"
                                            :team="convertToTeam(option.original)"
                                        />

                                        <div v-else />
                                    </template>

                                    <template #dropdown-bottom>
                                        <div
                                            v-b-modal="'modal-create-team'"
                                            class="dropdown-item py-2"
                                        >
                                            <btn class="btn-secondary btn-square mr-2">
                                                <i class="fa fa-plus" />
                                            </btn>

                                            {{ $t('teams.create.title') }}
                                        </div>
                                    </template>
                                </auto-complete>
                            </td>
                        </tr>
                    </template>

                    <template #noResults>
                        {{ $t('leagues.teams.edit.no_teams_added') }}
                    </template>
                </teams-table>
            </b-tab>
        </b-tabs>

        <teams-modal-save
            id="modal-create-team"
            :key="modalKey"
            @hidden="modalKey++"
            @saved="addTeamToCurrentGroup($event)"
        />

        <alert-resource-error :resource="league" />
    </div>
</template>

<script>
import {Team} from '@/models/Team';
import {League} from '@/models/League';
import {BTabs, BTab} from 'bootstrap-vue';
import TeamsTable from '@/components/teams/Table';
import TeamsModalSave from '@/components/teams/ModalSave';
import TeamsDdItem from '@/components/teams/DropdownItem';
import AutoComplete from '@/components/common/form/AutoComplete';

export default {
    name: 'LeaguesTeamsEdit',
    components: {AutoComplete, BTab, BTabs, TeamsDdItem, TeamsModalSave, TeamsTable},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Forces modal to rerender after it is hidden.
             */
            modalKey: 0,

            /**
             * The league group that is currently in the active tab.
             */
            currentGroup: this.league.league_groups.first(),

            Team,
        };
    },
    created() {
        // Fill up `league_groups`.
        for (let i = this.league.league_groups.length; i < this.league.max_groups; i++) {
            const letter = String.fromCharCode('A'.charCodeAt() + i);

            const group = {name: `Poule ${letter}`};

            this.league.league_groups.add(group);
        }
    },
    methods: {
        addTeamToCurrentGroup(team) {
            // Wrap `team` in {} because `team` is an attribute of `LeagueGroupTeam`.
            this.currentGroup.league_group_teams.add({team});
        },

        removeGroupTeamFromGroup(groupTeam, group) {
            group.league_group_teams.remove(groupTeam);
        },

        convertToTeam(teamObject) {
            return new Team(teamObject);
        },

        save() {
            const data = {
                league_groups: this.league.league_groups.map((group) => {
                    const team_ids = group.league_group_teams.map(lgt => lgt.team.id);

                    return {
                        id: group.id,
                        name: group.name,
                        team_ids,
                    };
                }),
            };

            this.league.save({data});
        },
    },
};
</script>
