<script>
import OwTable from '@/components/common/OwTable';
import TableRow from '@/components/teams/TableRow';

export default {
    name: 'TeamsTable',
    extends: OwTable,
    props: {
        headers: {
            type: Array,
            default: () => [
                'image', 'team', '',
            ],
        },
        tableRowComponent: {
            type: Object,
            default: () => TableRow,
        },
    },
};
</script>
