<template>
    <tr>
        <td
            v-if="columns.includes('image')"
            class="w-auto"
        >
            <ow-img
                v-if="row.team.image"
                class="img-table rounded-circle"
                :src="row.team.image"
                src-key="140"
            />

            <img
                v-else
                class="img-table rounded-circle"
                :src="var_placeholderSrc"
            >
        </td>

        <td v-if="columns.includes('team')">
            <strong>{{ row.team.name }} </strong>

            <span v-if="row.team.club_name">({{ row.team.club_name }})</span> <br>

            <small v-if="row.team.address">{{ row.team.address.toString() }}</small>
        </td>

        <td class="w-auto text-right">
            <btn-icon
                class="ml-4"
                @click="$emit('removeClicked', row)"
            />
        </td>
    </tr>
</template>

<script>
import TableRow from '@/components/common/TableRow';
import {LeagueGroupTeam} from '@/models/LeagueGroupTeam';

export default {
    name: 'TeamsTableRow',
    extends: TableRow,
    props: {
        row: {
            type: LeagueGroupTeam,
            required: true,
        },
    },
};
</script>
