<template>
    <div class="dropdown-item d-flex align-items-center py-2">
        <div class="mr-4">
            <ow-img
                v-if="team.image"
                class="img-table rounded-circle"
                :src="team.image"
                src-key="140"
            />

            <img
                v-else
                class="img-table rounded-circle"
                :src="var_placeholderSrc"
            >
        </div>

        <div>
            <strong>{{ team.name }} </strong>

            <span v-if="team.club_name">({{ team.club_name }})</span> <br>

            <small v-if="team.address">{{ team.address.toString() }}</small>
        </div>
    </div>
</template>

<script>
import {Team} from '@/models/Team';

/**
 * Dropdown item representation of a Team.
 */
export default {
    name: 'TeamsDropdownItem',
    props: {
        team: {
            type: Team,
            required: true,
        },
    },
};
</script>
